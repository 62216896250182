declare global {
    interface Window {
        WebFont: any;
    }
}

window.WebFont.load({
    google: {
        families: ['Open Sans', 'Noto Serif']
    }
});

export { };